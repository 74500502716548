import React from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import { Grid } from "@mui/material";
import logo from '../../assets/logo.png';

const Navbar = () => {
    return (
        <nav>
            <Grid container>
                <Grid item xs={12} md={12} >
                    <Grid container spacing={0} sx={{ backgroundColor: '#ffffff', pt: 2, pb: 2 }}>
                        <Grid item xs={4} md={2} sx={{ textAlign: 'left' }} key="brand">
                            <Link to="/" className="nav-link-brand"><img src={logo} style={{ maxWidth: '80%'  }} /></Link>
                        </Grid>
                        <Grid item xs={8} md={10} sx={{ textAlign: 'right' }} key="menu-item">
{/*                             <Link to="/browse/places" className="nav-link">Places</Link> */}
                            <Link to="/browse/stories" className="nav-link">Stories</Link>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </nav>
        
               
    );
}

export default Navbar;
