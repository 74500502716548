// Toast.js
import React from 'react';
import { useToastContext } from '../../context/ToastContext';
import './Toast.css'; // Create and import your CSS styles

const Toast = () => {
  const { toast } = useToastContext();

    console.log('Toast is', toast);
  return (
    toast.show && (
      <div className={`toast ${toast.type}`}>
        <p>{toast.message}</p>
      </div>
    )
  );
};

export default Toast;
